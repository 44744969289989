import {
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  replayIntegration,
} from "@sentry/vue";

export const sentryBrowserConfig = (options: {
  app: any;
  dsn: string;
  environment: string;
  release: string;
  apiUrl: string;
}) => {
  const { app, dsn, environment, release, apiUrl } = options;

  if (!import.meta.env.PROD || import.meta.env.SSR) {
    return;
  }
  init({
    app,
    dsn,
    integrations: (defaults) =>
      defaults.concat(
        browserTracingIntegration(),
        browserProfilingIntegration(),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        })
      ),
    tracesSampleRate: 0.1,
    profilesSampleRate: 0.1,
    autoSessionTracking: import.meta.env.SSR, // disable autoSessionTracking in SSR
    tracePropagationTargets: [
      /^\//,
      import.meta.env.SSR ? process.env.VITE_API_SSR_HOST! : apiUrl,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release,
    environment,
  });
};
